<template>
  <div class="d-flex flex-column text-typography-primary requests-popover">
    <div class="d-flex header justify-content-between align-items-center">
      <div class="fw-bold">{{ title }}</div>
      <div class="d-flex header-buttons gap-2">
        <Button
          v-if="requests.length > 0"
          :disabled="showCreateRequest"
          type="icon"
          class="m-0 p-0 add-button"
          @click="showCreateRequest = true"
        >
          <PlusIcon class="plus-icon" :size="20" />
        </Button>
        <Button type="icon" class="m-0 p-0 close-button" @click="$emit('close')">
          <CloseIcon :size="20" />
        </Button>
      </div>
    </div>
    <div v-if="showCreateRequest" class="d-flex create-request">
      <CreateRequestForm
        :task-id="taskId"
        :target-business-options="targetBusinessOptions"
        :templates="templates"
        @close="onRequestFormClose"
        @create-request="createRequest"
        @template-selected="onTemplateSelected"
      />
    </div>
    <div class="requests">
      <div v-for="(request, index) in requests" :key="index">
        <RequestsButtonPopoverRequestItem
          :key="index"
          :request="request"
          :class="{ divider: index !== requests.length - 1 }"
          @show-close-request-modal="$emit('show-close-request-modal', index)"
          @show-verdict-modal="(params) => $emit('show-verdict-modal', { ...params, requestIndex: index })"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue';
import { DateTime } from 'luxon';
import { reject, isNil } from 'ramda';
import moment from 'moment';

import { Button } from '@/modules/core';
import { CloseIcon, PlusIcon } from '@/assets/icons';

import CreateRequestForm from './CreateRequestForm';
import RequestsButtonPopoverRequestItem from './RequestsButtonPopoverRequestItem';

const ACTIONS = {
  CLOSE: 'close',
};

export default {
  name: 'RequestsButtonPopover',
  components: {
    CloseIcon,
    PlusIcon,
    Button,
    CreateRequestForm,
    RequestsButtonPopoverRequestItem,
  },
  props: {
    taskId: { type: String, required: true },
    targetBusinessOptions: { type: Array, default: () => [] },
    requests: { type: Array, required: true },
    templates: { type: Array, required: false, default: () => [] },
  },
  setup(props) {
    const showCreateRequest = ref(props.templates?.some((template) => template.selected));

    if (props.requests.length === 0) showCreateRequest.value = true;

    return { ACTIONS, showCreateRequest };
  },

  computed: {
    title() {
      return this.requests.length === 0
        ? this.$t('requests.requestsButton.popover.newRequest')
        : this.$t('requests.requestsButton.popover.requests');
    },
  },

  watch: {
    templates: {
      handler(newVal) {
        const isSelected = newVal.some((template) => template.selected);
        this.showCreateRequest = isSelected || this.showCreateRequest;
      },
      deep: true,
    },
  },
  methods: {
    onTemplateSelected(type) {
      this.$emit('template-selected', type);
    },
    createRequest(args) {
      this.showCreateRequest = false;
      this.$emit('create-request', reject(isNil, args));
      if (this.requests.length === 0) this.$emit('close');
    },
    onRequestFormClose() {
      this.showCreateRequest = false;
      if (this.requests.length === 0) this.$emit('close');
    },
    formatDate(date) {
      return DateTime.fromMillis(date).toFormat('dd.MM.yy, HH:mm');
    },
    getRelativeFromNowString(request) {
      return moment(request.activeAt).from(new Date());
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.requests-popover {
  width: 33.5rem;
  max-height: calc(85vh - 3rem);

  .divider {
    border-bottom: 1px solid $outline;
  }

  .header {
    padding: 0.75rem;
    width: 100%;
    border-bottom: 1px solid #d9dcde;
  }

  .create-request {
    padding: 1rem;
    border-bottom: 1px solid #d9dcde;
  }
}

.requests {
  overflow-y: auto;
}

.add-button,
.close-button {
  color: $typography-secondary;
  &:hover {
    background: #fff !important;
    color: $typography-primary !important;
  }
}

.add-button.is-disabled {
  ::v-deep path {
    color: $typography-disabled;
  }
}
</style>
