<template>
  <div class="my-4 d-flex flex-column">
    <div class="request p-3 rounded-1 mx-4 mb-4">
      <div class="d-flex justify-content-between">
        <div class="d-flex gap-2 align-items-center">
          <Tag>{{ $t(`requests.requestStatus.${transformedRequest.closed ? 'closed' : 'open'}`) }}</Tag>
          <div
            v-if="!transformedRequest.closed"
            :class="requestBackgroundClass"
            class="request-tag d-flex align-items-center gap-1 fw-bold"
          >
            <BellUserIcon v-if="requestOverdue && requestSnoozedByUser" :size="16" :fill="'#763700'" />
            <BellOutlinesIcon v-else-if="requestOverdue" :size="12" :fill="'#763700'" />
            <SnoozeIcon v-else :size="12" />
            <div>{{ getRelativeFromNowString(transformedRequest.activeAt) }}</div>
          </div>
        </div>
        <div v-if="!transformedRequest.closed" class="d-flex">
          <el-dropdown
            class="d-flex justify-content-center"
            trigger="click"
            @command="(command) => handleAction(command, transformedRequest)"
          >
            <Button type="icon" class="text-typography-primary">
              <KebabIcon width="20px" height="20px" />
            </Button>
            <el-dropdown-menu>
              <el-dropdown-item :command="ACTIONS.CLOSE">
                <div class="d-flex align-items-center gap-2 ps-2">
                  <CheckCircleIcon />
                  <p>{{ $t('requests.actions.close') }}</p>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="d-flex gap-2 align-items-center mt-1 mb-2">
        <p class="fw-bold">
          {{ $t('requests.requestFor', { targetBusiness: transformedRequest.targetBusiness.name }) }}
        </p>
        <span class="fw-bold">·</span>
        <small>{{ requestCreatedBy }}</small>
        <span class="fw-bold">·</span>
        <el-tooltip placement="top" effect="dark">
          <div slot="content">
            <small>{{ formatMsDate(transformedRequest.createdAt) }}</small>
          </div>
          <small>{{ getRelativeFromNowString(transformedRequest.createdAt) }}</small>
        </el-tooltip>
      </div>
      <p v-if="transformedRequest.details" class="pt-1 text-typography-primary details">
        {{ transformedRequest.details }}
      </p>
    </div>
    <div v-if="transformedRequest.responses?.length || transformedRequest.answer" class="responses my-3">
      <div class="d-flex flex-column gap-6">
        <div v-for="(response, i) in transformedRequest.responses" :key="i">
          <div class="d-flex align-items-baseline gap-2 mt-1 mb-2">
            <ReplyIcon width="16" height="16" class="text-typography-secondary" />
            <div class="d-flex gap-2 align-items-center">
              <p class="fw-bold">
                {{
                  $t('requests.responseFrom', {
                    responder: response.isCreatedByAdmin ? 'Clarity' : transformedRequest.targetBusiness.name,
                  })
                }}
              </p>
              <span class="fw-bold">·</span>
              <small>{{ response.createdBy?.firstName }} {{ response.createdBy?.lastName }}</small>
              <span class="fw-bold">·</span>
              <el-tooltip placement="top" effect="dark">
                <div slot="content">
                  <small>{{ formatMsDate(response.createdAt) }}</small>
                </div>
                <small>{{ getRelativeFromNowString(response.createdAt) }}</small>
              </el-tooltip>
            </div>
          </div>
          <div class="indent">
            <p v-if="response.text" class="mt-1 mb-3 text-typography-primary">
              {{ response.text }}
            </p>
            <div v-if="response.attachments?.length" class="d-flex flex-wrap gap-2">
              <div
                v-for="(attachment, index) in response.attachments"
                :key="index"
                class="attachment d-flex align-items-center gap-2"
                @click="showFile(response.filePathUrls[index])"
              >
                <PDFIcon />
                <small>{{ attachment.match(/[^/]+$/)[0] }}</small>
              </div>
            </div>
            <div v-if="response.reject" class="rejected d-flex gap-3 align-items-center mt-3">
              <div class="reject-icon"><CloseIcon :size="16" /></div>
              <el-tooltip placement="top" effect="dark">
                <div slot="content">
                  <small>{{
                    $t('requests.statusUpdatedBy', {
                      updatedBy: `${response.reject.createdBy?.firstName} ${response.reject.createdBy?.lastName}`,
                      timeAgo: getRelativeFromNowString(response.reject.createdAt),
                      date: formatMsDate(response.reject.createdAt),
                    })
                  }}</small>
                </div>
                <p>{{ response.reject.text }}</p>
              </el-tooltip>
            </div>
            <div v-else-if="!transformedRequest.closed" class="response-check p-3">
              <p class="fw-bold mb-3">{{ $t('requests.isResponseValid') }}</p>
              <div class="d-flex gap-2">
                <Button
                  type="secondary"
                  class="fw-bold"
                  @click="$emit('show-verdict-modal', { verdict: VERDICT.VALID, responseIndex: i })"
                  >{{ $t('requests.validResponse') }}
                </Button>
                <Button
                  type="secondary"
                  class="fw-bold"
                  @click="$emit('show-verdict-modal', { verdict: VERDICT.INVALID, responseIndex: i })"
                  >{{ $t('requests.invalidResponse') }}
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="transformedRequest.answer">
          <div class="d-flex align-items-baseline gap-2 mt-1 mb-2">
            <ReplyIcon width="16" height="16" class="text-typography-secondary" />
            <div class="d-flex gap-2 align-items-center">
              <p class="fw-bold">{{ $t('requests.responseFrom', { responder: 'Clarity' }) }}</p>
              <span class="fw-bold">·</span>
              <template v-if="requestClosedBy">
                <small>{{ requestClosedBy }}</small>
                <span class="fw-bold">·</span>
              </template>
              <el-tooltip placement="top" effect="dark">
                <div slot="content">
                  <small>{{ formatMsDate(transformedRequest.closedAt) }}</small>
                </div>
                <small>{{ getRelativeFromNowString(transformedRequest.closedAt) }}</small>
              </el-tooltip>
            </div>
          </div>
          <div class="indent mt-1 text-typography-primary">{{ answer }}</div>
        </div>
      </div>
      <div v-if="transformedRequest.closed" class="indent approved d-flex gap-3 align-items-center mt-3">
        <div class="approve-icon"><CheckIcon :size="16" /></div>
        <p v-if="!requestClosedBy">{{ $t('requests.requestClosed') }}</p>
        <el-tooltip v-else placement="top" effect="dark">
          <div slot="content">
            <small>{{
              $t('requests.statusUpdatedBy', {
                updatedBy: requestClosedBy,
                timeAgo: getRelativeFromNowString(transformedRequest.closedAt),
                date: formatMsDate(transformedRequest.closedAt),
              })
            }}</small>
          </div>
          <p>{{ $t('requests.requestClosed') }}</p>
        </el-tooltip>
      </div>
    </div>
    <el-dialog append-to-body :visible.sync="dialogVisible">
      <iframe :src="currentFileUrl" style="width: 100%; height: 50vh"></iframe>
    </el-dialog>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { computed, watch, ref, getCurrentInstance, toRefs } from 'vue';
import moment from 'moment';

import { Button, Tag } from '@/modules/core';
import {
  BellUserIcon,
  BellOutlinesIcon,
  SnoozeIcon,
  KebabIcon,
  CheckIcon,
  CheckCircleIcon,
  ReplyIcon,
  PDFIcon,
  CloseIcon,
} from '@/assets/icons';
import { useBusinessById } from '@/modules/business/compositions/business';
import { options } from '@/locale/dateConfig';
import { ROLE_TYPE } from '@/modules/users/constants';

import { USER_QUERY } from '../composition';

const ACTIONS = {
  CLOSE: 'close',
};

const VERDICT = {
  VALID: 'valid',
  INVALID: 'invalid',
};

export default {
  name: 'RequestsButtonPopoverRequestItem',
  components: {
    KebabIcon,
    BellUserIcon,
    BellOutlinesIcon,
    SnoozeIcon,
    CheckIcon,
    CheckCircleIcon,
    Button,
    Tag,
    ReplyIcon,
    PDFIcon,
    CloseIcon,
  },
  props: {
    request: { type: Object, required: true },
  },
  setup(props) {
    const root = getCurrentInstance().proxy;
    const { request } = toRefs(props);
    const transformedRequest = request;

    watch(
      request,
      async (request) => {
        request.responses?.[0]?.createdBy &&
          (await Promise.all(
            (request.responses ?? []).map((response) =>
              root.$apollo.query({ query: USER_QUERY, variables: { id: response.createdBy.id } }).then(({ data }) => ({
                ...response,
                isCreatedByAdmin: data.user.memberships.some(({ role }) => role === ROLE_TYPE.ADMIN),
              }))
            )
          ).then((data) => (transformedRequest.value = { ...request, responses: data })));
      },
      { immediate: true, deep: true }
    );

    const { business: requester } = useBusinessById(computed(() => ({ businessId: props.request.sourceBusinessId })));

    return {
      ACTIONS,
      requester,
      transformedRequest,
      currentFileUrl: ref(null),
      dialogVisible: ref(false),
      VERDICT,
    };
  },
  computed: {
    requestCreatedBy() {
      return this.transformedRequest.createdBy
        ? `${this.transformedRequest.createdBy.firstName} ${this.transformedRequest.createdBy.lastName}`
        : null;
    },
    date() {
      return this.transformedRequest.templateData[this.transformedRequest.template].reconciliationDate;
    },
    requestOverdue() {
      return (
        this.transformedRequest.activeAt &&
        DateTime.fromMillis(this.transformedRequest.activeAt) <= DateTime.fromJSDate(new Date())
      );
    },
    requestSnoozedByUser() {
      return this.transformedRequest.activeBy;
    },
    requestBackgroundClass() {
      return this.requestOverdue ? 'active-request' : 'inactive-request';
    },
    requestClosedBy() {
      return this.transformedRequest.closedBy
        ? `${this.transformedRequest.closedBy.firstName} ${this.transformedRequest.closedBy.lastName}`
        : null;
    },
    answer() {
      return this.request.answer === 'closed_by_system'
        ? this.$t('requests.requestsButton.popover.closed_by_system')
        : this.request.answer;
    },
  },
  methods: {
    formatMsDate(ms) {
      return DateTime.fromMillis(ms).toFormat('HH:mm, dd.MM.yy');
    },
    formatISODate(isoDate) {
      return isoDate && new Date(isoDate).toLocaleDateString(this.$i18n.locale, options.short);
    },
    getRelativeFromNowString(date) {
      return moment(date).from();
    },
    handleAction(command) {
      switch (command) {
        case ACTIONS.CLOSE:
          this.$emit('show-close-request-modal');
          break;
        default:
          break;
      }
    },
    showFile(url) {
      this.currentFileUrl = url;
      this.dialogVisible = true;
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/stylesheets/scss/global';
$gray: #fbfbfb;
$darken-gray: #e3e4e6;
$border-color: #bbbec2;

.request {
  background: $gray;
  border: 1px solid $darken-gray;
}

.request-tag {
  border-radius: 4px;
  font-size: 0.5625rem;
  padding: 0.25rem 0.375rem 0.25rem 0.5rem;
  height: 20px;
  line-height: 1;
  &.inactive-request {
    background-color: #f3f3f4;
    color: #46494f;
    border: 1px solid #d8e3e9;
  }
  &.active-request {
    background-color: #f9f2e8;
    color: #763700;
    border: 1px solid #fae3c1;
  }
}

.responses {
  margin-inline-start: 2.25rem;
  margin-inline-end: 1rem;
}

.details {
  word-break: break-word;
  white-space: pre-line;
}

.approved {
  color: #00552a;
}

.approve-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: #e9f5ef;
  border-radius: 50%;
}

.rejected {
  color: #970016;
}

.reject-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: #feeff2;
  border-radius: 50%;
}

.attachment {
  width: fit-content;
  padding: 0.25rem 0.375rem 0.25rem 0.5rem;
  border-radius: 4px;
  border: 1px solid #e3e4e6;
  cursor: pointer;
}

.response-check {
  border: 1px solid $border-color;
  border-radius: 0.5rem;
  width: fit-content;
  position: relative;
  margin-top: 1.25rem;
}

.response-check::before {
  content: '';
  position: absolute;
  top: -10px;
  right: 16px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid $border-color;
}

.response-check::after {
  content: '';
  position: absolute;
  top: -9px;
  right: 17px;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid #fff;
}

.indent {
  margin-inline-start: 1.5rem;
}
</style>
