<template>
  <div class="requests-button d-flex gap-1 px-3" :class="{ open }">
    <div class="request-icon">
      <el-tooltip v-if="responsesToCheck" placement="top" effect="dark">
        <div slot="content">
          {{ $tc('requests.responsesToCheck', responsesToCheck, { count: responsesToCheck }) }}
        </div>
        <div class="position-relative">
          <span>
            <RequestIcon :size="20" />
            <small class="responses-badge badge rounded-pill bg-danger position-absolute border border-2 border-white">
              {{ responsesToCheck > 99 ? '+99' : responsesToCheck }}
            </small>
          </span>
        </div>
      </el-tooltip>
      <div v-else>
        <RequestIcon :size="20" />
      </div>
    </div>
    <div class="d-flex gap-1">
      <el-tooltip placement="top" effect="dark">
        <div slot="content">
          <p>{{ $t('requests.requestsButton.tooltips.open') }}</p>
        </div>
        <span v-show="!!openRequestsCount" class="badge bg-primary open-requests">{{ openRequestsCount }}</span>
      </el-tooltip>
      <el-tooltip placement="top" effect="dark">
        <div slot="content">
          <p>{{ $t('requests.requestsButton.tooltips.closed') }}</p>
        </div>
        <span v-show="!!closedRequestsCount" class="badge closed-requests">{{ closedRequestsCount }}</span>
      </el-tooltip>
    </div>
    <div>
      <ChevronIcon v-if="showChevron" class="chevron-icon hoverable-icon" />
      <PlusIcon v-else class="plus-icon hoverable-icon" />
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';

import { ChevronIcon, PlusIcon, RequestIcon } from '@/assets/icons';

export default {
  name: 'RequestsButton',
  components: { ChevronIcon, PlusIcon, RequestIcon },
  props: {
    taskId: { type: String, required: true },
    targetBusinessOptions: { type: Array, required: true },
    requests: { type: Array, required: true },
    open: { type: Boolean, required: true },
  },
  setup(props) {
    const closedRequestsCount = computed(() => props.requests.filter((request) => request.closed).length);
    const openRequests = computed(() => props.requests.filter((request) => !request.closed));
    const openRequestsCount = computed(() => openRequests.value.length);
    const responsesToCheck = computed(() =>
      openRequests.value.reduce((count, { responses }) => {
        if (responses) {
          count += responses.filter(({ reject }) => !reject).length;
        }
        return count;
      }, 0)
    );

    return { closedRequestsCount, openRequestsCount, responsesToCheck };
  },
  computed: {
    showChevron() {
      return this.requests.length > 0;
    },
  },
};
</script>
<style scoped lang="scss">
@import '@/stylesheets/scss/global';

.request-icon {
  margin-left: 0.25rem;
}

.requests-button {
  padding: 0 0.75rem;
  display: flex;
  align-items: center;
  border: 1px solid #d2d4d7;
  border-radius: 0.25rem;
  height: 2.5rem;
  cursor: pointer;

  .hoverable-icon {
    color: $typography-secondary;
  }

  &.open {
    background: #f3f3f4;
  }

  &:hover {
    background: #f3f3f4;

    .hoverable-icon {
      color: black;
    }
  }

  .responses-badge {
    top: -20%;
    right: -20%;
    color: #fff;
    width: 15px;
    height: 15px;
    padding: 1px;
  }

  .badge {
    min-width: 1.125rem;
    height: 1.125rem;
    font-size: $font-size-small;
    font-weight: $font-weight-bold;
    border-radius: 50%;
    &.open-requests {
      color: white;
    }
    &.closed-requests {
      color: $typography-primary;
      background: #e3e4e6;
    }
  }
}
</style>
